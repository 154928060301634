import React, { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    fn: '',
    ln: '',
    em: '',
    msg: '',
    token: ''
  }

  const captcha = useRef();

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("SEND");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formDetails['token']) {
      setStatus({ success: false, message: "Please prove you're a human." });
    } else {
      setButtonText('Sending...');
      const res = await fetch('/PHP/contact.php', {
        method: 'POST',
        body: JSON.stringify(formDetails)
      });
      setButtonText('SEND');

      const result = await res.json();
      if (result.code === 200) {
        setStatus({ success: true, message: result.message });
        setFormDetails(formInitialDetails);
      } else {
        setStatus({ success: false, message: result.message });
      }

      captcha.current.resetCaptcha();
      onFormUpdate('token', '');
    }
  }

  return (
    <section className='contactForm' id='contact' >
      <Container>
        <h2>Contact Me!</h2>
        <Form onSubmit={handleSubmit} autoComplete='off'>
          <Row className="mb-3 formi">
            <Form.Group as={Col} className="formi" controlId="fFNgsbn">
              <Form.Label>First Name</Form.Label>
              <Form.Control required type="text" placeholder="First Name" value={formDetails.fn} onChange={(e) => onFormUpdate('fn', e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} className="formi" controlId="fLNgsbn">
              <Form.Label>Last Name</Form.Label>
              <Form.Control required type="text" placeholder="Last Name" value={formDetails.ln} onChange={(e) => onFormUpdate('ln', e.target.value)} />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3 formi" controlId="fEgsbn">
            <Form.Label>Email address</Form.Label>
            <Form.Control required type="email" placeholder="Enter email" value={formDetails.em} onChange={(e) => onFormUpdate('em', e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3 formi" controlId="fMgsbn">
            <Form.Label>Message</Form.Label>
            <Form.Control required as="textarea" rows={6} value={formDetails.msg} placeholder='Message' onChange={(e) => onFormUpdate('msg', e.target.value)} />
          </Form.Group>

          <Row className="formo">
            <Form.Group as={Col} className="formo" controlId="formFirstName">
              <Form.Label className="formo" >First Name</Form.Label>
              <Form.Control autoComplete='off' className="formo" type="text" placeholder="First Name" value={formDetails.firstName} onChange={(e) => onFormUpdate('firstName', e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} className="formo" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control autoComplete='off' type="text" placeholder="Last Name" value={formDetails.lastName} onChange={(e) => onFormUpdate('lastName', e.target.value)} />
            </Form.Group>
          </Row>
          <Form.Group className="formo" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control autoComplete='off' type="email" placeholder="Enter email" value={formDetails.email} onChange={(e) => onFormUpdate('email', e.target.value)} />
          </Form.Group>
          <Form.Group className="formo" controlId="formMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control autoComplete='off' as="textarea" rows={6} value={formDetails.message} placeholder='Message' onChange={(e) => onFormUpdate('message', e.target.value)} />
          </Form.Group>

          <Form.Check
            required
            type="checkbox"
            id="formCheckBox"
            label="By submitting this form you agree and accept our Privacy Policy"
          />

          <HCaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            onVerify={(value) => onFormUpdate('token', value)}
            onExpire={() => onFormUpdate('token', '')}
            ref={captcha}
          />
          
          <Button variant="info" type="submit">
            {buttonText}
          </Button>
          {
            status.message &&
            <Col>
              <p className={status.success === false ? 'danger' : 'success'}>{status.message}</p>
            </Col>
          }
        </Form>
      </Container>
    </section>
  )
}

export default Contact